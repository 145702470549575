import React, { Component } from 'react';
import TableFilter from 'react-table-filter';
import logo from './logo.svg';
import './App.css';
import './styles.css';
import Login from './components/Login/Login';
import Home from './components/Home/home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavbarApp from './components/Navbar/NavbarApp';
import ListRubriques from './components/ListRubriques/ListRubriques';

const dataToLoadFromApi = [
  {
    "nom": "Belhafiane",
    "prenom": "Adil",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "REDA",
    "prenom": "LASNAMI",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "REDA",
    "prenom": "LASNAMI",
    "atelier": "customer focus interactions",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "REDA",
    "prenom": "LASNAMI",
    "atelier": "buying ladder",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Belhafiane",
    "prenom": "Adil",
    "atelier": "customer focus interactions",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Belhafiane",
    "prenom": "Adil",
    "atelier": "buying ladder",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "top to top meeting",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "trust & value negotiation skills",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "REDA",
    "prenom": "LASNAMI",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "REDA",
    "prenom": "LASNAMI",
    "atelier": "top to top meeting",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "El Hadri",
    "prenom": "Ibtissam",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "El Hadri",
    "prenom": "Ibtissam",
    "atelier": "self empowerment",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "customer focus interactions",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "gaining customer insights",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "top to top meeting",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "trust & value negotiation skills",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "global business analysis and planning",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "buying ladder",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Khaled",
    "prenom": "Housseme",
    "atelier": "the power of persuasion",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Classroom Trainings"
  },
  {
    "nom": "Belrhzal",
    "prenom": "Adil",
    "atelier": "Value Demonstration & Affordability",
    "sale": "mr3",
    "temp": "09:30 - 10:30",
    "jour": "",
    "pas": "",
    "rubrique": "Cycle Meeting Workshops"
  },
  {
    "nom": "Belrhzal",
    "prenom": "Adil",
    "atelier": "Finance for non finance",
    "sale": "mr2",
    "temp": "08:30 - 09:30",
    "jour": "",
    "pas": "",
    "rubrique": "Cycle Meeting Workshops"
  },
  {
    "nom": "Belrhzal",
    "prenom": "Adil",
    "atelier": "digital g2n Frct accuracy",
    "sale": "mr1",
    "temp": "12:00 - 13:00",
    "jour": "",
    "pas": "",
    "rubrique": "Cycle Meeting Workshops"
  },
  {
    "nom": "Belhafiane",
    "prenom": "Adil",
    "atelier": "Leading people",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "Feedback essentials",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "Global collaboration",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "Goal setting",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Essaibari",
    "prenom": "Ouidad",
    "atelier": "Finance Essentials",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Affane",
    "prenom": "Aziz",
    "atelier": "Customer  focus",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Alami",
    "prenom": "Abla",
    "atelier": "Leveraging your networks",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "E-learning Trainings"
  },
  {
    "nom": "Belhafiane",
    "prenom": "Adil",
    "atelier": "Internal Communication",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Game Learn"
  },
  {
    "nom": "Ait Oumtjar",
    "prenom": "Adil",
    "atelier": "Team Management",
    "sale": "",
    "temp": "",
    "jour": "",
    "pas": "",
    "rubrique": "Game Learn"
  }
]

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      "data":dataToLoadFromApi,
      loginorout:false
    };
   
    this._filterUpdated = this._filterUpdated.bind(this);
    this.changeloginstate = this.changeloginstate.bind(this);
  }

  _filterUpdated(newData){
    
		this.setState({
			"data": newData
    }); 
    console.log(newData)
  }
  
  changeloginstate(val){
    this.setState({
      loginorout:val
    })
  }
 
  
  render() {

    const elementsHtml = this.state.data.map((item, index) => {
			return (
				<tr key={"row_"+index}>
					<td className="cell">
						{ item.nom }
					</td>
					<td className="cell">
						{ item.prenom }
					</td>
					<td className="cell">
						{ item.atelier }
					</td>
				</tr>
			);
    });
    
    return (
    
   
      <Router>
        
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route path="/home/:rubrique" component={ListRubriques}/>
          </Switch>
      </Router>
     
    
    );
  }
}

export default App;
