import React,{Component} from 'react';
import MuiDataTable from "react-mui-datatables";
import axios from 'axios';
import { Button, Paper } from "@material-ui/core/es/index";
import _ from 'lodash';




const data = [
    {
      fname: "Jayne",
      lname: "Washington",
      email: "jaynewashington@exposa.com",
      gender: "female"
    },
    {
      fname: "Peterson",
      lname: "Dalton",
      email: "petersondalton@exposa.com",
      gender: "male"
    },
    {
      fname: "Velazquez",
      lname: "Calderon",
      email: "velazquezcalderon@exposa.com",
      gender: "male"
    },
    {
      fname: "Norman",
      lname: "Reed",
      email: "normanreed@exposa.com",
      gender: "male"
    },
    {
      fname: "Morales",
      lname: "Collins",
      email: "moralescollins@exposa.com",
      gender: "male"
    },
    {
      fname: "Mccormick",
      lname: "Merrill",
      email: "mccormickmerrill@exposa.com",
      gender: "male"
    },
    {
      fname: "Carrillo",
      lname: "Daugherty",
      email: "carrillodaugherty@exposa.com",
      gender: "male"
    },
    {
      fname: "Conley",
      lname: "Mcintyre",
      email: "conleymcintyre@exposa.com",
      gender: "male"
    },
    {
      fname: "Hahn",
      lname: "Santiago",
      email: "hahnsantiago@exposa.com",
      gender: "male"
    },
    {
      fname: "Daugherty",
      lname: "Roth",
      email: "daughertyroth@exposa.com",
      gender: "male"
    },
    {
      fname: "Antonia",
      lname: "Richards",
      email: "antoniarichards@exposa.com",
      gender: "female"
    },
    {
      fname: "Suzanne",
      lname: "Hoover",
      email: "suzannehoover@exposa.com",
      gender: "female"
    },
    {
      fname: "Richard",
      lname: "Malone",
      email: "richardmalone@exposa.com",
      gender: "male"
    },
    {
      fname: "Marissa",
      lname: "Leonard",
      email: "marissaleonard@exposa.com",
      gender: "female"
    },
    {
      fname: "Keller",
      lname: "Duffy",
      email: "kellerduffy@exposa.com",
      gender: "male"
    },
    {
      fname: "Leblanc",
      lname: "Boone",
      email: "leblancboone@exposa.com",
      gender: "male"
    },
    {
      fname: "Marva",
      lname: "Pugh",
      email: "marvapugh@exposa.com",
      gender: "female"
    },
    {
      fname: "Grimes",
      lname: "Harrison",
      email: "grimesharrison@exposa.com",
      gender: "male"
    },
    {
      fname: "Bright",
      lname: "Stokes",
      email: "brightstokes@exposa.com",
      gender: "male"
    },
    {
      fname: "Cotton",
      lname: "Massey",
      email: "cottonmassey@exposa.com",
      gender: "male"
    },
    {
      fname: "Price",
      lname: "Carrillo",
      email: "pricecarrillo@exposa.com",
      gender: "male"
    },
    {
      fname: "Sherrie",
      lname: "Mcfarland",
      email: "sherriemcfarland@exposa.com",
      gender: "female"
    },
    {
      fname: "Billie",
      lname: "Velasquez",
      email: "billievelasquez@exposa.com",
      gender: "female"
    },
    {
      fname: "Tanner",
      lname: "Barton",
      email: "tannerbarton@exposa.com",
      gender: "male"
    },
    {
      fname: "Terrell",
      lname: "Matthews",
      email: "terrellmatthews@exposa.com",
      gender: "male"
    },
    {
      fname: "Dina",
      lname: "Lowery",
      email: "dinalowery@exposa.com",
      gender: "female"
    },
    {
      fname: "Meadows",
      lname: "Thornton",
      email: "meadowsthornton@exposa.com",
      gender: "male"
    },
    {
      fname: "Sadie",
      lname: "Sherman",
      email: "sadiesherman@exposa.com",
      gender: "female"
    },
    {
      fname: "Agnes",
      lname: "Bush",
      email: "agnesbush@exposa.com",
      gender: "female"
    },
    {
      fname: "Gay",
      lname: "Walters",
      email: "gaywalters@exposa.com",
      gender: "female"
    },
    {
      fname: "Angela",
      lname: "Wyatt",
      email: "angelawyatt@exposa.com",
      gender: "female"
    },
    {
      fname: "Dotson",
      lname: "Farrell",
      email: "dotsonfarrell@exposa.com",
      gender: "male"
    },
    {
      fname: "Brandy",
      lname: "Joyce",
      email: "brandyjoyce@exposa.com",
      gender: "female"
    },
    {
      fname: "Margie",
      lname: "Clark",
      email: "margieclark@exposa.com",
      gender: "female"
    },
    {
      fname: "Castillo",
      lname: "Taylor",
      email: "castillotaylor@exposa.com",
      gender: "male"
    },
    {
      fname: "Frye",
      lname: "House",
      email: "fryehouse@exposa.com",
      gender: "male"
    },
    {
      fname: "Roxie",
      lname: "Decker",
      email: "roxiedecker@exposa.com",
      gender: "female"
    },
    {
      fname: "Pitts",
      lname: "Stark",
      email: "pittsstark@exposa.com",
      gender: "male"
    },
    {
      fname: "Serrano",
      lname: "Harvey",
      email: "serranoharvey@exposa.com",
      gender: "male"
    },
    {
      fname: "Dunlap",
      lname: "Oneal",
      email: "dunlaponeal@exposa.com",
      gender: "male"
    },
    {
      fname: "Mcleod",
      lname: "Baxter",
      email: "mcleodbaxter@exposa.com",
      gender: "male"
    },
    {
      fname: "Wilkinson",
      lname: "Holden",
      email: "wilkinsonholden@exposa.com",
      gender: "male"
    },
    {
      fname: "Josefa",
      lname: "Butler",
      email: "josefabutler@exposa.com",
      gender: "female"
    },
    {
      fname: "Lydia",
      lname: "Evans",
      email: "lydiaevans@exposa.com",
      gender: "female"
    },
    {
      fname: "Wilson",
      lname: "Davidson",
      email: "wilsondavidson@exposa.com",
      gender: "male"
    },
    {
      fname: "Malinda",
      lname: "Carver",
      email: "malindacarver@exposa.com",
      gender: "female"
    },
    {
      fname: "Michele",
      lname: "Pruitt",
      email: "michelepruitt@exposa.com",
      gender: "female"
    },
    {
      fname: "Strong",
      lname: "Shaffer",
      email: "strongshaffer@exposa.com",
      gender: "male"
    },
    {
      fname: "Benson",
      lname: "Mccoy",
      email: "bensonmccoy@exposa.com",
      gender: "male"
    },
    {
      fname: "Barton",
      lname: "Medina",
      email: "bartonmedina@exposa.com",
      gender: "male"
    }
  ];
var columns = [];
export default class ListRubriques extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            data: [],
            columns:[
                { label: "Nom", key: "nom",  options: {
                    filter: true,
                    sort: true,
                   } },
                { label: "Prenom", key: "prenom", sort: true },
                { label: "atelier", key: "atelier" },
                { label: "sale", key: "sale" },
                { label: "temp", key: "temp"} ,
                { label: "jour", key: "jour"} ,
                { label: "pas", key: "pas"} ,
                { label: "rubrique", key: "rubrique"} ,
            ],
            updateit:false
            
        };
    }

    componentWillMount(){
        const { rubrique } = this.props.match.params;
        this.getRubriques(rubrique);
        
    }

    async  getRubriques(rubrique) {
        try {
          const response = await axios.get('http://inclusionapi.grafezia.com/api/display.php/?rubrique='+rubrique);
          
          //console.log(response.data);
          let copycolumns = this.state.columns;
          
              
                    if(response.data[0].sale =="")
                        copycolumns.splice(_.findIndex(copycolumns, {label: 'sale'}), 1);
                    if(response.data[0].jour =="")
                            copycolumns.splice(_.findIndex(copycolumns, {label: 'jour'}), 1);
                    if(response.data[0].temp=="")
                            copycolumns.splice(_.findIndex(copycolumns, {label: 'temp'}), 1);
                    if(response.data[0].pas=="")
                            copycolumns.splice(_.findIndex(copycolumns, {label: 'pas'}), 1);

                    
         
          console.log(copycolumns)
           //console.log(newdata) ;
          this.setState({
            data:response.data,
            columns:copycolumns,
            updateit:true
            
          });

                  
             


          
          //console.log(this.state.data);
          
          
        } catch (error) {
          console.error(error);
        }
    }


    render(){

                            
      
          const action = (
            <Button onClick={()=>console.log('hello')}>Action</Button>
          ); /* <-- action button */
      

          const options = {
            hasIndex: true /* <-- use numbers for rows*/,
            customAction: action /* <-- use action button for row */,
            searchBox: true /* <-- search true or false */,
            csv: true /* <-- csv download true or false */,
            indexColumn:
              "nom" /* <-- add your data first unique column name for this like _id, i used fname because i don't have a _id field in my array */,
            printButton: true,
            sort:true,
            filter: true
          };
      

        return(

            <div>
                {
                    this.state.updateit &&
                
                <Paper style={{ padding: 16, margin: 16 }}>
                    <MuiDataTable
                        data={this.state.data}
                        columns={this.state.columns}
                        options={options}
                        title={"Detail:"}
                        sort={true}
                        filter={true}
                    />
                </Paper>
            }
            </div>
        );
    }
}