import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,

 } from 'reactstrap';

export default class NavbarApp extends React.Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">Admin Back office</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
        </Navbar>
      </div>
    );
  }
}