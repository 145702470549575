import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button
 } from 'reactstrap';
 import {Redirect} from 'react-router-dom';

export default class Navbar2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loguserout:false
    };
    this.logout = this.logout.bind(this);
  }

  

  logout(){
    console.log('log user out ');
    localStorage.removeItem('userData');
    this.setState({loguserout:true})
  }

  render() {
    if( this.state.loguserout){
        return (<Redirect to={'/'}/>)
        //console.log(sessionStorage.getItem('userData'))
    } 
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">Admin Back office</NavbarBrand>
          <Nav className="ml-auto" navbar>
                        <NavItem>
                          <Button color="link" onClick={this.logout}>Se Deconnecter</Button>
                        </NavItem>
          </Nav>
           
          
          
        </Navbar>
      </div>
    );
  }
}