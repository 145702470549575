import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import NavbarApp from '../Navbar/NavbarApp';

import {
    Container, Col, Form,
    FormGroup, Label,
    Button, Alert,Input
  } from 'reactstrap';

import {PostData} from '../../services/PostData';
import './Login.css';
const axios = require('axios');

var insert = [];

class Login extends Component {

  constructor(){
    super();
   
    this.state = {
     username: '',
     password: '',
     redirectToReferrer: false,
     warningforuser:''
    };

    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentWillMount(){
      console.log(this.props);
  }

  login() {
    /* if(this.state.username && this.state.password){
      PostData('login',this.state).then((result) => {
       let responseJson = result;
       if(responseJson.userData){         
         sessionStorage.setItem('userData',JSON.stringify(responseJson));
         this.setState({redirectToReferrer: true});
       }
       
      });
    } */

    let self = this;
    
    var element=new Object();
    element.username = this.state.username;
    element.password = this.state.password;
    insert.push(element);
    var params = new URLSearchParams();
    params.append('arr',JSON.stringify(insert) );
    axios.post('http://inclusionapi.grafezia.com/api/checkuserexists.php', params)
      .then(function (response) {
        console.log(response);
        if(response.data!==null){
             console.log('yay user is found ')  
             localStorage.setItem('userData',JSON.stringify(response));
             self.setState({redirectToReferrer: true}) 
             
        }else {
            console.log('no such user');
            self.setState({warningforuser:'utilisateur introuvable !'})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    /* axios.post('http://inclusionapi.grafezia.com/api/checkuserexists.php',
    this.state,{
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
       }
    })
    .then((res)=>console.log(res))
    .catch((err)=>console.log(err)); */

    
    
   }


  handleChange (event)  {
    const {target} = event;  
    const value =  target.value;
    const { name } = target;
    this.setState({
      [ name ]: value,
    }); 
    console.log(this.state.username, this.state.password);
  }

  
  

  render() {

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/home'}/>)
    } 
   
     if(localStorage.getItem('userData')){
      return (<Redirect to={'/home'}/>)
    } 

     return (
    <div>
        <NavbarApp/> 
        <Container className="App">
        
        <h2>S'autnetifier:</h2>
        <Form className="form">
          <Col>
            <FormGroup>
              <Label>Utilisateur:</Label>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder="Veuillez fournir votre identifiant "
                value={this.state.username}
                onChange={ (e)=> this.handleChange(e)}

              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="examplePassword">Mot de Passe:</Label>
              <Input
                type="password"
                name="password"
                id="userpassword"
                placeholder="********"
                value={this.state.password}
                onChange = { (e)=> this.handleChange(e)}
                
            />
            </FormGroup>
          </Col>
          <Button onClick={this.login}>Se Connecter</Button>
          <br />
          {
              this.state.warningforuser !== '' ? <Alert style={{marginTop:5}} color="warning">{this.state.warningforuser}</Alert> : ''
          }
      </Form>
      </Container>
      </div>
    );
  }
}

export default Login;