import React,{Component} from 'react';
import {
    ListGroup, ListGroupItem
  } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import Navbar2 from '../Navbar/Navbar2';
import axios from 'axios';
import ReactLoading from 'react-loading';
import _ from 'lodash';

class Home extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            loaded:false,
            rubriques:[]
        };
        this.getRubriques = this.getRubriques.bind(this);
        this.renderList = this.renderList.bind(this);
        
    }

    componentWillMount(){
        console.log('component willl mount ')
        this.getRubriques();
        
    }
     
    

    async  getRubriques() {
        try {
          const response = await axios.get('http://inclusionapi.grafezia.com/api/display.php');
          //localStorage.setItem('alldata',response.data);
          const duplicated = _.uniqBy(response.data, function (e) {
            return e.rubrique;
          });
          this.setState({
            data:response.data,
            loaded:true,
            rubriques:duplicated
          });
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
    }

    renderList(){
        const list = this.state.rubriques.map((d)=>{
            return (
                <ListGroupItem key={_.uniqueId('rubrique_')} tag="a" href={"/home/" +d.rubrique} >{d.rubrique}</ListGroupItem>
            )
        });
        return list;
    }

    render(){
        console.log(localStorage.getItem('userData'))
         if( localStorage.getItem('userData')===null){
            return (<Redirect to={'/'}/>)
            //console.log(sessionStorage.getItem('userData'))
        }  

        return (
            <div>
                <Navbar2/>
                <h3 style={{marginTop:70,marginLeft:15}}>List des rubriques choisies :</h3>
                {
                    !this.state.loaded ? <ReactLoading type="bubbles" color="red" height={667} width={375} />:
                     (
                        <ListGroup style={{marginLeft:15}}>
                            {this.renderList()}
                            
                         </ListGroup>
                     )
                }
                
            </div>
        )
    }

}

export default Home;